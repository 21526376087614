/**
 * Creates a debounced function that delays invoking the provided function until
 * after the specified wait time has elapsed since the last time the debounced
 * function was invoked. The debounced function always returns a Promise that
 * resolves to the value returned from the debounced function.
 *
 * @template T - The type of the function to debounce.
 * @param func - The function to debounce. The function can either return a
 *   promise or be synchronous. In either case, the debounced function will
 *   return a promise that resolves to the value returned from the debounced
 *   function.
 * @param waitFor - The number of milliseconds to delay.
 * @returns A new debounced function returning a promise that resolves to the
 *   value returned from the debounced function.
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  waitFor: number,
) {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>) =>
    new Promise<Awaited<ReturnType<T>>>((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        try {
          resolve(func(...args));
        } catch (error) {
          reject(error);
        }
      }, waitFor);
    });
}
