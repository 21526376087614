import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import { GlobalCompanySuggestion } from './generalApiTypes';

export function generalApi({ baseUrl, get }: AppApiPartProps) {
  return {
    async getGlobalCompanySuggestions({
      query,
      signal,
    }: {
      query: string;
      signal: AbortSignal;
    }) {
      return await get<GlobalCompanySuggestion>(
        `${baseUrl}/global-companies/suggestions?query=${query}`,
        { signal },
      );
    },
  };
}
