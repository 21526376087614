import { createApiQueryHook } from 'app/api/app-api/apiHooksHelpers';

export const useUsersListQuery = createApiQueryHook(
  (filters) => ['legoland', 'users', filters],
  'getUsersList',
);

export const useContractSuggestedUserListQuery = createApiQueryHook(
  (filters) => ['legoland', 'users/contract-suggestions', filters],
  'getContractSuggestedUserListQuery',
);
