import { RegistrationFormValues } from 'app/types/User';
import isEmail from 'validator/es/lib/isEmail';
import { array, bool, object, string } from 'yup';

/**
 * A list of email domains that are not allowed for registration.
 *
 * This array contains domains that are commonly used for personal email
 * addresses and are therefore restricted to ensure users register with a more
 * professional or organizational email address.
 */
const forbiddenDomains = ['gmail.com', 'google.com', 'yahoo.com', 'yandex.ru'];

/**
 * Checks if the given email has an allowed domain for registration.
 *
 * @param email - The email address to validate.
 * @returns `true` if the email domain is allowed for registration, `false`
 *   otherwise.
 */
function isAllowedEmailDomain(email: string) {
  const usesForbiddenDomain = forbiddenDomains.some((domain) =>
    email.toLowerCase().endsWith(domain),
  );
  return !usesForbiddenDomain;
}

/** Schema for the registration form validation. */
export const registrationFormSchema = object<Partial<RegistrationFormValues>>({
  email: string()
    .ensure()
    .trim()
    .required('Email is required')
    .test({
      name: 'email',
      message: `Email is invalid.`,
      test: (value) => value !== '' && isEmail(value),
    })
    .test({
      name: 'domain',
      message:
        'Please enter your work email address during the registration process. ' +
        'Use the "Support" form on the login page if you have any questions.',
      test: isAllowedEmailDomain,
    }),
  firstName: string()
    .trim()
    .max(255, 'Name cannot have more than 255 characters.'),
  lastName: string()
    .trim()
    .max(255, 'Name cannot have more than 255 characters.'),
  country: string().required('Country is required.'),
  industry: string(),
  company: object({
    id: string().optional(),
    name: string()
      .trim()
      .required('Company name is required.')
      .max(255, 'Company name cannot have more than 255 characters.'),
  }),
  products: array(string()).min(1, 'At least one product must be selected.'),
  termsAgreement: bool().oneOf([true], 'Your consent is required.'),
});
