import { useMemo } from 'react';

/**
 * Converts an array of strings into an array of objects suitable for use as
 * select options.
 *
 * Each object in the returned array will have a `value` and `label` property,
 * both set to the corresponding string.
 *
 * @param strings - The array of strings to convert. If not provided or `null`,
 *   an empty array will be returned.
 * @returns An array of objects with `value` and `label` properties.
 */
export function stringsToSelectOptions(strings?: string[] | null) {
  return (
    strings?.map((string) => ({
      value: string,
      label: string,
    })) || []
  );
}

/**
 * Custom hook that converts an array of strings into select options.
 *
 * This is a memoized version of `stringsToSelectOptions` that can be used in
 * functional components.
 *
 * Each object in the returned array will have a `value` and `label` property,
 * both set to the corresponding string.
 *
 * @param strings - The array of strings to convert. If not provided or `null`,
 *   an empty array will be returned.
 * @returns The memoized array of select options derived from the input strings.
 */
export function useStringsToSelectOptions(strings?: string[] | null) {
  return useMemo(() => stringsToSelectOptions(strings), [strings]);
}
